<script setup>
    import {ref, inject} from "vue";

    import DefaultLayout from '../layouts/DefaultLayout.vue'
    import Masthead from '../components/Masthead.vue'
    import Input from '../components/elements/Input.vue'
    import PrimaryButton from '../components/elements/PrimaryButton.vue'

    import store from "../store/data";
    import {useRoute, useRouter} from "vue-router";
    import axios from 'axios'
    const route = useRoute()
    const router = useRouter()

    const site = ref(store.getSite())
    const sdk = inject('sdk')

    const countries = ref([])
    const errors = ref([])
    const processing = ref(false)
    const success = ref(false)
    const honeypot  = ref(null)
    const verticals = ref([
        'Animal and Pet Supplies',
        'Art and Photography',
        'Beauty and Personal Care',
        'Books, Music and Video',
        'Charity',
        'Clothes, Shoes and Accessories',
        'Computers and Electronics',
        'Digital',
        'Food and Beverages',
        'Furniture and Home Interiors',
        'Gifts',
        'Healthcare and Medical',
        'Health and Fitness',
        'Hobbies and Crafts',
        'Jewellery',
        'Lifestyle',
        'Sport and Outdoors',
        'Toys and Games',
        'Other'
    ])
    const subscriptions = ref([
        {
            label: 'Starter - Free forever',
            value: 'freemium'
        },
        {
            label: 'Growth - 3 months free, then £25 per month',
            value: 'paid'
        },
        {
            label: 'Community - £50 per month',
            value: 'community'
        }
    ])

    const handleIconClick = (node, e) => {
        node.props.suffixIcon = node.props.suffixIcon === 'eye' ? 'eyeClosed' : 'eye'
        node.props.type = node.props.type === 'password' ? 'text' : 'password'
    }

    // Kill switch
    if (import.meta.env.VITE_APP_SIGN_UP_ALLOWED === 'false') {
        router.push({name: 'home'})
    }

    const data = ref({
        name: null,
        country_id: "826", // UK default
        email: null,
        first_name: null,
        last_name: null,
        password: null,
        vertical: null,
        subscription: 'plan' in route.query ? route.query['plan'] : 'freemium',
        vertical_other: null,
        reason: null,
        referral_url: document.referrer ? document.referrer : null
    })

    sdk.getCountries().then(response => {
        countries.value  = response.countries.map((country) => {
            return {
                label: country.name,
                value: country.id.toString()
            }
        })
    })

    const formSubmit = async () => {
        if (processing.value === true) {
            return false
        }

        errors.value = []
        processing.value = true
        success.value = false

        if (honeypot.value.value !== 'honey') {
            errors.value.push('Could not register an account due to suspicious activity')
            return;
        }

        const request = {
            name: data.value.name,
            country_id: data.value.country_id,
            vertical: data.value.vertical_other ? 'Other - ' + data.value.vertical_other : data.value.vertical,
            admin_email: data.value.email,
            subscription: data.value.subscription,
            reason: data.value.reason,
            referral_url: data.value.referral_url,
            user: {
                email: data.value.email,
                first_name: data.value.first_name,
                last_name: data.value.last_name,
                password: data.value.password
            },
            channels: [2], // catalog,
            store_type_id: 4 // social commerce
        }

        axios.post(import.meta.env.VITE_APP_SIGN_UP_API_URL, request, {
            headers: {
                'Authorization': 'Bearer ' + import.meta.env.VITE_APP_SIGN_UP_API_KEY
            }
        }).then(response => {
            processing.value = false
            success.value = true
        }).catch(error => {
            processing.value = false
            success.value  = false

            if ('errors' in error.response.data) {
                error.response.data.errors.forEach(e => {
                    errors.value.push(e.message)
                })
                return;
            }

            errors.value.push('Unknown error')
        })
    }
</script>

<template>
    <DefaultLayout :include-launch="false">
        <Masthead class="bg-blue-light-alt" title="Create your store" text="Fill in the details below to create your free social store and start selling."></Masthead>
        <section class="bg-blue-light-alt">
            <div class="container pb-16 lg:max-w-3xl">
                <div class="bg-white p-8 rounded-lg" data-aos="fade-in">
                    <div v-if="success" class="prose prose-black lg:text-lg">
                        <h3>Your store has been created.</h3>
                        <p>Check your email for your activation link and login to the platform to start selling.</p>
                        <PrimaryButton href="https://app.sugar-coat.io" title="Login to Sugarcoat">
                            Login
                        </PrimaryButton>
                    </div>
                    <div v-else>
                        <FormKit
                                type="form"
                                :incomplete-message="false"
                                :actions="false" @submit="formSubmit"
                                :errors="errors"
                                #default="{ state: { valid } }"
                        >
                            <h3 class="mb-2">Tell us about your store</h3>
                            <Input label="What do you want your store to be called?*" name="name" type="text"
                                   v-model="data.name"
                                   validation-label="Store name"
                                   validation="required"
                            />

                            <Input label="Where will you sell from?*" name="country_id" type="select"
                                   :options="countries"
                                   v-model="data.country_id"
                                   placeholder="Please select an option"
                                   validation-label="Country"
                                   validation="required"
                            />

                            <Input label="What type of products will you be selling?*" name="vertical" type="select"
                                   :options="verticals"
                                   v-model="data.vertical"
                                   placeholder="Please select an option"
                                   validation-label="Type of product"
                                   validation="required"
                            />

                            <Input v-if="data.vertical === 'Other'" label="Please tell us more*" name="vertical_other" type="text"
                                   v-model="data.vertical_other"
                                   validation-label="More product information"
                                   validation="required"
                                   outer-class="pl-12"
                            />

                            <Input label="Which pricing plan suits you?*" name="subscription" type="select"
                                   :options="subscriptions"
                                   v-model="data.subscription"
                                   placeholder="Please select a plan"
                                   validation-label="Plan"
                                   validation="required"
                            />

                            <Input label="What benefits are you looking to get from Sugarcoat?" name="reason" type="textarea"
                                   v-model="data.reason"
                                   validation="length:0,1024"
                            />

                            <h3 class="mt-8 mb-2">Tell us about you</h3>

                            <div class="lg:flex lg:gap-4">
                                <Input label="Your first name*" name="first_name" type="text"
                                       v-model="data.first_name"
                                       validation-label="First name"
                                       validation="required"
                                />
                                <Input label="Your last name*" name="last_name" type="text"
                                       v-model="data.last_name"
                                       validation-label="Last name"
                                       validation="required"
                                />
                            </div>

                            <Input label="Your email*" name="email" type="email"
                                   v-model="data.email"
                                   validation-label="Email"
                                   validation="required"
                            />

                            <div class="lg:flex lg:gap-4">
                                <Input label="Choose a password*" name="password" type="password"
                                       v-model="data.password"
                                       validation-label="Password"
                                       validation="required"
                                       suffix-icon="eyeClosed"
                                       @suffix-icon-click="handleIconClick"
                                />
                                <Input label="Confirm your password*" name="password_confirm" type="password"
                                       validation-label="Password confirmation"
                                       validation="required|confirm"
                                       suffix-icon="eyeClosed"
                                       @suffix-icon-click="handleIconClick"
                                />
                            </div>    

                            <label class="mt-6 flex" for="terms_and_conditions">
                                <div>
                                    <Input id="terms_and_conditions" name="terms_and_conditions" type="checkbox"
                                        validation-label="Terms and Conditions"
                                        validation="required"
                                    />
                                </div>
                                <span>I agree to the <a class="font-bold" href="https://www.sugar-coat.io/docs/terms_of_service.pdf">platform terms of service</a> and <a class="font-bold" href="https://www.sugar-coat.io/privacy-policy" target="_blank">privacy policy</a>.</span>
                            </label>

                            <input ref="honeypot" name="field" type="text" class="absolute top-[-9999px]" autocomplete="off" value="honey" />

                            <PrimaryButton type="button" class="mt-2 w-full" :disabled="!valid || processing" :class="{'opacity-50': !valid || processing}">
                                Launch your store
                            </PrimaryButton>
                        </FormKit>
                    </div>
                </div>
            </div>
        </section>
    </DefaultLayout>
</template>