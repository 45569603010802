export function stepsList() {
    return [
        {
            title: "Create your store",
            theme: "purple",
            content: "Create a free account today, no credit card or long terms commitments"
        },
        {
            title: "Setup your store",
            theme: "orange",
            content: "Quickly set up your store, create your first product and connect your bank in a just few minutes."
        },
        {
            title: "Share your store",
            theme: "green",
            content: "Easily share your store and products from any social channel or link-in-bio tool."
        }
    ]
}

export function benefitList() {
    return [
        {
            title: "Quicker",
            theme: "purple",
            features: [
                {
                    title: "Store setup in minutes"
                },
                {
                    title: "No website required"
                },
                {
                    title: "No technical experience required"
                },
                {
                    title: "Pre-configured social integrations"
                },
                {
                    title: "Scheduled social catalog updates"
                }
            ]
        },
        {
            title: "Cheaper",
            theme: "orange",
            features: [
                {
                    title: "No monthly licence fees"
                },
                {
                    title: "Low cost payment fees"
                },
                {
                    title: "Choose your own payment gateways"
                },
                {
                    title: "No long-term commitments"
                }
            ]
        },
        {
            title: "Easier",
            theme: "green",
            features: [
                {
                    title: "No website to configure"
                },
                {
                    title: "No content to generate"
                },
                {
                    title: "Instant checkout"
                },
                {
                    title: "Checkout & payment links"
                },
                {
                    title: "Powerful management platform"
                }
            ]
        }
    ]
}

export function audienceList() {
    return [
        {
            title: "Independent Sellers",
            url: "/independent-sellers",
            features: [
                {
                    title: "Bespoke Stores",
                    description: "From just £25/month, create your own bespoke storefront through Sugarcoat and get ready to showcase your business to the world."
                },
                {
                    title: "Analytics & Reporting",
                    description: "Review and act upon the performance of your store with our powerful real-time analytics and reporting capabilities."
                },
                {
                    title: "Customer Management",
                    description: "Easily manage, communicate and report on your store customers in one place."
                },
                {
                    title: "Reliable Deliveries",
                    description: "With flexible delivery configuration, you can always ensure the right delivery options are presented to your customers."
                },
                {
                    title: "Manage Orders",
                    description: "Whether online or offline, manage your store orders in one place with automated customer notifications and order status workflows."
                },
                {
                    title: "Customer Notifications",
                    description: "Store-wide notifications for common actions and events such as sales, returns, stock notifications and more - ensuring you and your customers are always in the know."
                }
            ]
        },
        {
            title: "Businesses",
            url: "/businesses",
            features: [
                {
                    title: "Headless Commerce",
                    description: "Our industry-leading API gives you the tools and flexibility to build your own solutions and automated workflows."
                },
                {
                    title: "Developer Tools",
                    description: "Utilise our extensive toolkit of SDK libraries and useful integration features to bring your project to life."
                },
                {
                    title: "Flexible Hosting",
                    description: "Have complete control over the hosting of your store and get your business online in minutes."
                },
                {
                    title: "Content Management",
                    description: "Getting your online store up and running has never been easier thanks to our in-house Content Management System and templating solution. Easily create, duplicate and customise page templates and content pages within your website."
                },
                {
                    title: "Secure Payments",
                    description: "With level 1 PCI compliance, safely and securely take payments from anywhere in the world in over 135 different currencies."
                },
                {
                    title: "Product Feeds",
                    description: "Always ensure your products remain up to date with our industry-leading automated Product Feeds solution, or work with us to integrate your own feeds."
                }
            ]
        },
        {
            title: "Influencers",
            url: "/influencers",
            features: [
                {
                    title: "Headless Commerce",
                    description: "Our industry-leading API gives you the tools and flexibility to build your own solutions and automated workflows."
                },
                {
                    title: "Developer Tools",
                    description: "Utilise our extensive toolkit of SDK libraries and useful integration features to bring your project to life."
                },
                {
                    title: "Flexible Hosting",
                    description: "Have complete control over the hosting of your store and get your business online in minutes."
                },
                {
                    title: "Content Management",
                    description: "Getting your online store up and running has never been easier thanks to our in-house Content Management System and templating solution. Easily create, duplicate and customise page templates and content pages within your website."
                },
                {
                    title: "Secure Payments",
                    description: "With level 1 PCI compliance, safely and securely take payments from anywhere in the world in over 135 different currencies."
                },
                {
                    title: "Product Feeds",
                    description: "Always ensure your products remain up to date with our industry-leading automated Product Feeds solution, or work with us to integrate your own feeds."
                }
            ]
        },
        {
            title: "Agencies",
            url: "/agencies",
            features: [
                {
                    title: "Bespoke Stores",
                    description: "From just £20/month, create your own bespoke storefront through Sugarcoat and get ready to showcase your business to the world."
                },
                {
                    title: "Headless Commerce",
                    description: "Our industry-leading API gives you the tools and flexibility to build your own solutions and automated workflows."
                },
                {
                    title: "Flexible Hosting",
                    description: "Have complete control over the hosting of your store and get your business online in minutes."
                },
                {
                    title: "Content Management",
                    description: "Getting your online store up and running has never been easier thanks to our in-house Content Management System and templating solution. Easily create, duplicate and customise page templates and content pages within your website."
                },
                {
                    title: "Secure Payments",
                    description: "With level 1 PCI compliance, safely and securely take payments from anywhere in the world in over 135 different currencies."
                },
                {
                    title: "Product Feeds",
                    description: "Always ensure your products remain up to date with our industry-leading automated Product Feeds solution, or work with us to integrate your own feeds."
                }
            ]
        }
    ]
}

export function productList() {
    return [
        {
            title: 'Product Catalog',
            slug: null,
            description: 'Embrace no-code, no-website ecommerce',
            nav_text: null,
            summary: 'Self-serve and no-code embeddable catalog available anywhere and accessible by anyone with a unified commerce experience for all social channels',
            theme: 'green',
            image: 'img/content/product/catalog.png'
        },
        {
            title: 'Social Integrations',
            slug: null,
            description: 'Connect to your socials, all in one place',
            nav_text: null,
            summary: 'Easily keep your social products up to date with a single product catalog with seamless integrations to all leading social media platforms',
            theme: 'red',
            image: 'img/content/product/social-integrations.png'
        },

        {
            title: 'Powerful Checkout',
            slug: null,
            description: 'Fast and flexible checkout for more sales',
            nav_text: null,
            summary: 'Customisable and powerful checkout experience for your customers with flexible payment options to drive up your sales',
            theme: 'purple',
            image: 'img/content/product/checkout.png'
        },
        {
            title: 'Checkout Links',
            slug: null,
            description: 'Sell your products quicker with a simple link',
            nav_text: null,
            summary: 'Push your customers directly into the checkout flow for your products, saving time and improving your customers experience whilst making direct message exchanges more valuable',
            theme: 'orange',
            image: 'img/content/product/checkout-links-dm.png'
        },
        {
            title: 'Payment Links',
            slug: null,
            description: 'Making payments as simple as possible',
            nav_text: null,
            summary: 'Accept payments for your products and services from a single link within seconds with no code required',
            theme: 'yellow',
            image: 'img/content/product/payment-links-white.png'
        },
        {
            title: 'Management Platform',
            slug: null,
            description: 'One powerful management platform',
            nav_text: null,
            summary: 'Manage your store all in one place with a feature-packed user friendly management platform',
            theme: 'blue',
            image: 'img/content/product/platform.png'
        }
    ]
}

export function getPageContentByKey(page, key) {
    const content = page.content.filter(content => content.content_type && content.content_type.key === key)

    if (content.length > 0) {
        return content[0]
    }

    return null;
}

export function formatDate(dateString) {
    const date = new Date(dateString.replace(" ", "T"))
    return date.toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })
}


export function parsePageContent(page) {
    page.content.forEach(content => {
        if (content.content_type) {
            try {
                const jsonContent = JSON.parse(content.value);
                content.value = jsonContent
            } catch (e) {
                console.debug(e)
            }
        }
    })

    return page
}

export function articleTheme(articleType) {
    const themes = {
        'news': 'green-light',
        'blog': 'blue-light-alt',
        'case-studies': 'yellow-light'
    }

    return themes[articleType]
}

export function formatAsset(asset, params) {
    let url = asset

    url = url.replace('assets.sugar-coat.io/', 'assets.sugar-coat.io/cdn-cgi/image/' + params + '/');

    return url
}