<script setup>
    import { ref} from "vue"
    import PrimaryButton from '../elements/PrimaryButton.vue'
    import TextFadeUp from '../elements/TextFadeUp.vue'
    import Tick from '../elements/Tick.vue'
    import Input from '../elements/Input.vue'

    const currency  = ref('£')

    const currencies = ref({
        '£': 'GBP',
        '$': 'USD',
        '€': 'EUR'
    })

    const panels = ref([
        {
            title: 'Starter',
            price: 'Free',
            unit: null,
            text: 'Always free. No catch.',
            features: [
                '1 staff account',
                'Unlimited orders',
                '100 products',
                '{currency}50k/year sales volume',
                '2.5% payment fee'
            ],
            button_text: 'Get Started',
            button_url: '/sign-up?plan=freemium'
        },{
            title: 'Growth',
            price: '{currency}25',
            unit: 'month',
            text: 'Higher limits. 3 months free.',
            features: [
                'Unlimited staff accounts',
                'Unlimited orders',
                '10,000 products',
                '{currency}250k/year sales volume',
                '1% payment fee'
            ],
            button_text: 'Get Started',
            button_url: '/sign-up?plan=paid'
        },{
            title: 'Beyond',
            price: 'Custom',
            unit: null,
            text: 'Customised for your needs.',
            features: [
                'Unlimited staff accounts',
                'Unlimited orders',
                'Unlimited products',
                'Unlimited sales volume',
                '0% payment fee',
            ],
            button_text: 'Get In Touch',
            button_url: '/contact',
        }
    ])
</script>

<template>
    <section class="bg-white">
        <div class="container py-16 lg:py-32 lg:max-w-7xl">
            <div class="text-center max-w-2xl m-auto mb-8 lg:mb-16">
                <h2><TextFadeUp text="Ready to Sell in Minutes for free" /></h2>
            </div>
            <Input label="Currency" name="country_id" type="select"
                   :options="currencies"
                   v-model="currency"
                   outer-class="max-w-[200px] ml-auto"
                   wrapper-class="flex gap-2 items-center"
                   placeholder="Please select an option"
                   validation-label="Country"
                   validation="required"
            />
            <div class="grid gap-8 items-center lg:grid-cols-3">
                <div v-for="(panel, index) in panels" :key="index" class="h-full rounded-lg p-8 lg:p-10 bg-blue-light-alt"
                     data-aos="fade-in" :data-aos-delay="index * 50">
                    <h3 class="mb-6">{{ panel.title.replace("{currency}", currency) }}</h3>
                    <p class="text-lg mb-0"><span class="h2">{{ panel.price.replace("{currency}", currency) }}</span>{{ panel.unit ? '/' + panel.unit : ''}}</p>
                    <p class="prose mb-8">{{ panel.text.replace("{currency}", currency) }}</p>
                    <div class="prose mb-10">
                        <ul class="list-none p-0">
                            <li v-for="feature in panel.features" class="pl-0 flex items-center">
                                <Tick class="h-[24px] w-[24px] my-0 mr-2 bg-blue-light text-blue" />
                                {{ feature.replace("{currency}", currency) }}
                            </li>
                        </ul>
                    </div>
                    <PrimaryButton :href="panel.button_url" :title="panel.title" data-aos="fade-up" class="w-full">
                        {{ panel.button_text}}</PrimaryButton>
                </div>
            </div>
        </div>
    </section>
</template>